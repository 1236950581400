import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import "./../../styles/dark-mode.scss"
import Loader from "react-loader-spinner"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import SuccessMessage from "../../components/success"

export default ({
  data: {
    pageInfo: {
      mainTitle,
      mainContent,
      mainSubtext,
      sidebarHeader,
      sidebarContact,
      sidebarContactLink,
      sidebarAddress,
      successThankyou,
      successMessage,
      successCTA,
      successLabel,
      successLink,
    },
    newsDescendingOrder: { nodes: newsDescendingOrder },
  },
}) => {
  const [loading, isLoading] = useState(true)

  return (
    <Layout fluid={true} pageInfo={{ pageName: "First Responders Contact" }}>
      <SEO
        title="First Responders Contact"
        keywords={[`Contact`, `first responders`]}
      />
      <Container id="dark-mode" fluid>
        <SuccessMessage
          news={newsDescendingOrder}
          successThankyou={successThankyou}
          successMessage={successMessage}
          successCTA={successCTA}
          successLabel={successLabel}
          successLink={successLink}
        />
        <Container className="formSubmit">
          <Row>
            <Col className="info-section" md={8}>
              <h1 className="">{mainTitle}</h1>
              <h2>{mainSubtext}</h2>
              <p>{mainContent}</p>
              <iframe
                allowtransparency="true"
                frameborder="0"
                height="1000"
                src="https://go.pardot.com/l/1007222/2023-09-20/52t3q2"
                style={{ border: "0" }}
                type="text/html"
                width="100%"
              ></iframe>
            </Col>
            <Col className="contact-section" md={4}>
              <h4>{sidebarHeader}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: `<p>${sidebarContact}<a class="email-link" href="mailto:${sidebarContactLink}">${sidebarContactLink}</a></p>`,
                }}
              ></div>
              <div
                dangerouslySetInnerHTML={{ __html: `<p>${sidebarAddress}</p>` }}
              ></div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Layout>
  )
}

export const SuccessQuery = graphql`
  query FPContactQuery {
    pageInfo: pagesYaml(slug: { eq: "fp-contact" }) {
      mainTitle
      mainContent
      mainSubtext
      sidebarHeader
      sidebarContact
      sidebarContactLink
      sidebarAddress
      successThankyou
      successMessage
      successCTA
      successLabel
      successLink
    }
    newsDescendingOrder: allNodeNews(
      sort: { order: DESC, fields: [created] }
      limit: 3
    ) {
      nodes {
        id
        title
        caption: field_caption
        source: field_source
        published: created(formatString: "MMMM D, YYYY")
        relationships {
          field_image {
            relationships {
              field_media_image {
                thumbnail: localFile {
                  childImageSharp {
                    fixed {
                      width
                      height
                      originalName
                      src
                      srcSet
                    }
                  }
                }
                featuredImage: localFile {
                  childImageSharp {
                    fixed(width: 1108, height: 444) {
                      width
                      height
                      src
                      srcSet
                      aspectRatio
                      originalName
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
